import React, { useEffect, useState } from "react";
import { useParams, useNavigate , useLocation} from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NAACPdfViewer = ({ data, dataKey = "ID", filenameKey = "filename", pathKey = "pathurl" }) => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id, name  } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 10000);
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    if (location.state?.selectedDocData) {
      setSelectedDocData(location.state.selectedDocData);
      setLoading(false);
    }
  }, [location.state]);

  const handleBackClick = () => navigate(-1);

  if (!selectedDocData) {
    return (
      <Box textAlign="center" my={6}>
        <Typography variant="h2">No data found.</Typography>
        <Button
              variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box textAlign="center" my={4}>
        <CircularProgress />
        <Typography variant="h6" mt={2}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box my={4} className="container">
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Button
              variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
            <Typography variant="h5" textAlign="center" flexGrow={1}>
              {selectedDocData
                ? selectedDocData[filenameKey]
                : "No file available"}
            </Typography>
          </Box>
          <iframe
            src={
              selectedDocData?.[pathKey]?.endsWith(".pdf")
                ? selectedDocData?.[pathKey]
                : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                    selectedDocData?.[pathKey] || ""
                  )}`
            }
            title="Document Viewer"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default NAACPdfViewer;
