import { Outlet, useRoutes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./Component/Layout";
import AboutUsPage from "./Pages/Aboutus/Aboutus";
import ActivityPage from "./Pages/Activities/Activity";
import ContactUsPage from "./Pages/Contactus/Contactus";
// import CoursePage from "./Pages/Course/Course";
// import CourseOfferingPage from "./Pages/Course_Offering/Course_Offering";
import CourseOfferedPage from "./Pages/Department/Department.js";
import HomePage from "./Pages/Home";
import FacultyPage from "./Pages/Faculty/Faculty";
import NIRFComponentPage from "./Pages/NIRF/NIRF.js";
import NIRFExtendedPage from "./Pages/NIRF/NIRFExtended.js";
import NAACComponentPage from "./Pages/NAAC/NAAC.js";
import NAACExtendedPage from "./Pages/NAAC/NaccExtended.js";
import NAAC_Data from "./Assets/JSON/NAAC/Naac.json";
import Extendednaacdata from "./Assets/JSON/NAAC/NAACExtended_Data.json";
import FeedbackPage from "./Pages/Feedback/Feedback";
import PlacementsPage from "./Pages/Placements/placements";
import AboutIQACPage from "./Pages/IQAC/AboutIQAC";
import IQACObjectivePage from "./Pages/IQAC/IQAC_Objective";
import IQACParameterPage from "./Pages/IQAC/IQAC_Parameters";
import QualityPolicyPage from "./Pages/IQAC/Quality_Policy";
import MandatoryDocPage from "./Pages/IQAC/Mandatory_doc";
import WorkingCommitteePage from "./Pages/IQAC/Working_Commitee";
import InfrastructurePage from "./Pages/Aboutus/Infrastructure";
import AllStudentsPage from "./Pages/Placements/allstudentsplacedlist";
import Error404 from "./Error404";
import SocialBar from './Pages/Socialbar/Socialbar.js';
import MandatoryPDF from "./Component/Pdfcomponents/mandatorypdf.js";
import Naacpdf from "./Component/Pdfcomponents/naacpdf.js";
import NIRFPDF from "./Component/Pdfcomponents/nirfpdf.js";
import NaacuploadPdfViewer from "./Component/Pdfcomponents/naacuploadpdf.js";
// import NCC from "./Pages/StudentCorner/NCC";
// import NSS from "./Pages/StudentCorner/NSS";
// import Sports from "./Pages/StudentCorner/Sports";
import "./App.css";
import PdfViewer from "./Component/Pdfcomponents/PdfViewer.js";

const AppRoutes = () => {
  const location = useLocation();
  const ExtendedNaacData = Extendednaacdata.NaacExtended_Data;

    useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  const routesNav = [
    { element: <HomePage />, path: "/" },
    { element: <HomePage />, path: "/home" },
    { element: <CourseOfferedPage />, path: "/courseoffering/:name/:subname" },
    // { element: <CoursePage />, path: "/course" },
    { element: <FacultyPage />, path: "/faculty"},
    { element: <AboutUsPage />, path: "/aboutus" },
    { element: <MandatoryPDF /> , path: "/mandatorydoc/:id/:pathurl"},
    { element: <ActivityPage />, path: "/activity" },
    { element: <ContactUsPage />, path: "/contactus" },
    { element: <NIRFComponentPage />, path: "/nirf" },
    { element: <NIRFPDF />, path: "/nirf/:id/:pathurl" },
    { element: <NIRFExtendedPage />, path: "/NIRFExtended" },
    { element: <NAACComponentPage />, path: "/naac" },
    { element: <NAACExtendedPage />, path: "/NAACExtended/:id/:name" },
    {
      element: <PdfViewer data={NAAC_Data} backPath={"/naac"} />,
      path: "/naac/:id/:pathname",
    },
    {
      element: <Naacpdf data={ExtendedNaacData} backPath="/NAACExtended" />,
      path: "/extendednaac/:id/:pathname",
    },
    { element: <NaacuploadPdfViewer />, path: "/naacdocs/:conatiner/:year/:course/:pathurl" },
    { element: <NaacuploadPdfViewer />, path: "/naacdocs/:conatiner/:pathurl" },
    { element: <AboutIQACPage />, path: "/aboutIQAC" },
    { element: <IQACObjectivePage />, path: "/IQACObjective" },
    { element: <IQACParameterPage />, path: "/IQACParameter" },
    { element: <QualityPolicyPage />, path: "/Qualitypolicy" },
    { element: <MandatoryDocPage />, path: "/mandatorydoc" },
    { element: <WorkingCommitteePage />, path: "/workingcommitee" },
    { element: <FeedbackPage />, path: "/feedback" },
    { element: <InfrastructurePage />, path: "/infrastructure" },
    { element: <PlacementsPage />, path: "/placements" },
    { element: <AllStudentsPage />, path: "/allStudents" },
    { element: <Error404 />, path: "*" },

    // { element: <NCC />, path: "/NCC" },
    // { element: <NSS />, path: "/NSS" },
    // { element: <Sports />, path: "/sports" },
  ];

  const routesData = [
    {
      element: <Layout />,
      children: [
        {
          element: <Outlet />,
          children: routesNav,
        },
      ],
    },
  ];

  return  (
    <div> {/* Use a container for styling */}
      {useRoutes(routesData)}
      <SocialBar />
    </div>
  );
};

export default AppRoutes;
