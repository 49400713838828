import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";

const NaacuploadPdfViewer = ({
  data,
  dataKey = "ID",
  filenameKey = "filename",
  pathKey = "pathurl",
}) => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { conatiner,year,course,pathurl } = useParams();

useEffect(() => {
const baseUrl = "https://dbraeiassetmanagement.blob.core.windows.net/";
  let constructedPath = `${baseUrl}${conatiner}/`;

  if (year) {
    constructedPath += `${year}/`;
  }

  if (course) {
    constructedPath += `${course}/`;
  }

  constructedPath += pathurl;

  setSelectedDocData(constructedPath || null);
  setLoading(false);
}, [conatiner, year, course, pathurl]);

useEffect(() => {
  const timer = setTimeout(() => setLoading(false), 10000);
  return () => clearTimeout(timer);
}, [loading]);

if (!selectedDocData) {
  return (
    <Box textAlign="center" my={4}>
      <Typography variant="h6">No data found.</Typography>
    </Box>
  );
}

if (loading) {
  return (
    <Box textAlign="center" my={4}>
      <CircularProgress />
      <Typography variant="h6" mt={2}>
        Loading...
      </Typography>
    </Box>
  );
}

const getViewerUrl = (filePath) => {
  const fileExtension = filePath.split(".").pop().toLowerCase();

  switch (fileExtension) {
    case "pdf":
      return filePath;
    case "xlsx":
    case "docx":
    case "doc":
      return `https://docs.google.com/gview?url=${encodeURIComponent(
        filePath
      )}&embedded=true`;
    default:
      return null;
  }
};
const viewerUrl = getViewerUrl(selectedDocData);
  return (
    <Box my={4} className="container">
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5" textAlign="center"  flexGrow={1}>
              {selectedDocData
                ? pathurl
                : "No file available"}
            </Typography>
          </Box>
          <iframe
            src={viewerUrl || ""}
            title="PDF Viewer"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default NaacuploadPdfViewer;
