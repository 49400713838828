import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PdfViewer = ({
  data,
  dataKey = "ID",
  filenameKey = "filename",
  pathKey = "pathurl",
}) => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const searchLevels = (levels, idToFind) => {
    for (const key in levels) {
      if (
        ["level1", "level2", "level3","level4","level5","level6","level7", "level8","level9","level10"].includes(key) &&
        Array.isArray(levels[key])
      ) {
        const item = levels[key].find((subDoc) => subDoc[dataKey] === idToFind);
        if (item) return item;
      } else if (typeof levels[key] === "object") {
        const result = searchLevels(levels[key], idToFind);
        if (result) return result;
      }
    }
    return null;
  };

  useEffect(() => {
    const idToFind = parseInt(id, 10);
    let foundData =
      data.find((doc) => doc[dataKey] === idToFind) ||
      data.reduce(
        (acc, doc) => acc || searchLevels(doc.URLs || {}, idToFind),
        null
      );
    setSelectedDocData(foundData || null);
    setLoading(false);
  }, [data, dataKey, id]);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 10000);
    return () => clearTimeout(timer);
  }, [loading]);

  const handleBackClick = () => navigate(-1);

  if (!selectedDocData) {
    return (
      <Box textAlign="center" my={4}>
        <Typography variant="h6">No data found.</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box textAlign="center" my={4}>
        <CircularProgress />
        <Typography variant="h6" mt={2}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box my={4} className="container">
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Button
              variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
            <Typography variant="h5" textAlign="center" flexGrow={1}>
              {selectedDocData
                ? selectedDocData[filenameKey]
                : "No file available"}
            </Typography>
          </Box>
          <iframe
            src={
              selectedDocData?.[pathKey]?.endsWith(".pdf")
                ? selectedDocData?.[pathKey]
                : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                    selectedDocData?.[pathKey] || ""
                  )}`
            }
            title="Document Viewer"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default PdfViewer;